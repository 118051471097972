import { template as template_3bf3ea89e2a84a38b9fb0d33d00b46ef } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3bf3ea89e2a84a38b9fb0d33d00b46ef(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
