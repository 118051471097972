import { template as template_a510d71cbe4d40b39326b238a8f8d458 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a510d71cbe4d40b39326b238a8f8d458(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
