import { template as template_f7629dc7c0614addafb8b23e5ed85628 } from "@ember/template-compiler";
const WelcomeHeader = template_f7629dc7c0614addafb8b23e5ed85628(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
